import { PoolDeployedBlockNumber } from './types'

const poolsDeployedBlockNumber: PoolDeployedBlockNumber = {
  '0x73feaa1eE314F8c655E354234017bE2193C9E24E': 699498,
  '0x09e727c83a75fFdB729280639eDBf947dB76EeB7': 14068005,
  '0x2718D56aE2b8F08B3076A409bBF729542233E451': 13930663,
  '0x2461ea28907A2028b2bCa40040396F64B4141004': 13723466,
  '0x9e31aef040941E67356519f44bcA07c5f82215e5': 13694629,
  '0x1c0C7F3B07a42efb4e15679a9ed7e70B2d7Cc157': 13668585,
  '0x56Bfb98EBEF4344dF2d88c6b80694Cba5EfC56c8': 13517499,
  '0x07984aBb7489CD436d27875c07Eb532d4116795a': 13315659,
  '0xF1fA41f593547E406a203b681df18acCC3971A43': 13265669,
  '0x13A40BFab005D9284f8938FBb70Bf39982580e4D': 13156390,
  '0x0914b2d9D4DD7043893DEF53ecFC0F1179F87d5c': 13100655,
  '0xd97ee2bfe79a4d4ab388553411c462fbb536a88c': 13073445,
  '0x2EfE8772EB97B74be742d578A654AB6C95bF18db': 12967830,
  '0x7F103689cabe17C2F70DA6faa298045d72a943b8': 12942691,
  '0xbd52ef04DB1ad1c68A8FA24Fa71f2188978ba617': 12748434,
  '0x73bB10B89091f15e8FeD4d6e9EBa6415df6acb21': 12720810,
  '0xdD52FAB121376432DBCBb47592742F9d86CF8952': 12692166,
  '0x2b8751B7141Efa7a9917f9C6fea2CEA071af5eE7': 12583296,
  '0xfDFb4DbE94916F9f55dBC2c14Ea8B3e386eCD9F9': 12556338,
  '0x79f5f7DDADeFa0A9e850DFFC4fBa77e5172Fe701': 12386824,
  '0x9b861A078B2583373A7a3EEf815bE1A39125Ae08': 12352983,
  '0xa35caA9509a2337E22C54C929146D5F7f6515794': 12331745,
  '0x6e63B2B96c77532ea7ec2B3D3BFA9C8e1d383f3C': 12161143,
  '0xFef4B7a0194159d89717Efa592384d42B28D3926': 12133079,
  '0x2D26e4b9a5F19eD5BB7AF221DC02432D31DEB4dA': 12105173,
  '0xd008416c2c9cf23843bd179aa3cefedb4c8d1607': 11964262,
  '0xd9b63bb6c62fe2e9a641699a91e680994b8b0081': 11964263,
  '0xCc2D359c3a99d9cfe8e6F31230142efF1C828e6D': 11789725,
  '0x65C0940C50A3C98AEEc95a115Ae62E9804588713': 11707445,
  '0x6f660c58723922c6f866a058199ff4881019b4b5': 11593585,
  '0xc28c400F2B675b25894FA632205ddec71E432288': 12499891,
  '0x8d018823d13c56d62ffb795151a9e629c21e047b': 11131942,
  '0x4D1Ec426d0d7fb6bF344Dd372d0502EDD71c8d88': 10874023,
  '0xCB41a72067c227D6Ed7bc7CFAcd13eCe47Dfe5E9': 10731524,
  '0xcecba456fefe5b18d43df23419e7ab755b436655': 10673172,
  '0x8ed7acf12b08274d5cdaf03d43d0e54bcbdd487e': 10560115,
  '0xC4b15117BC0be030c20754FF36197641477af5d1': 10531042,
  '0xB72dEf58D0832f747d6B7197471Fe20AeA7EB463': 10502091,
  '0xb38b78529bCc895dA16CE2978D6cD6C56e8CfFC3': 10360932,
  '0x2E101b5F7f910F2609e5AcE5f43bD274b1DE09AA': 10305925,
  '0x52733Ad7b4D09BF613b0389045e33E2F287afa04': 10189589,
  '0x401b9b97bdbc3197c1adfab9652dc78040bd1e13': 9933419,
  '0xBeDb490970204cb3CC7B0fea94463BeD67d5364D': 9875995,
  '0xb6e510ae2da1ab4e350f837c70823ab75091780e': 9787670,
  '0x8aa5b2c67852ed5334c8a7f0b5eb0ef975106793': 9677422,
  '0x3b804460c3c62f0f565af593984159f13b1ac976': 9677361,
  '0x455f4d4cc4d6ca15441a93c631e82aaf338ad843': 9593263,
  '0xde4aef42bb27a2cb45c746acde4e4d8ab711d27c': 9570241,
  '0x57d3524888ded4085d9124a422f13b27c8a43de7': 9543912,
  '0xb56299d8fbf46c509014b103a164ad1fc65ea222': 9515302,
  '0x5e49531BA07bE577323e55666D46C6217164119E': 9408776,
  '0xBB472601B3CB32723d0755094BA80B73F67f2AF3': 9371201,
  '0x583A36816F3b8401C4fdf682203E0caDA6997740': 9371225,
  '0x28050e8f024e05f9ddbef5f60dd49f536dba0cf0': 9313000,
  '0xb2b62f88ab82ed0bb4ab4da60d9dc9acf9e816e5': 9197147,
  '0xd1812e7e28c39e78727592de030fc0e7c366d61a': 9082458,
  '0x97058cf9b36c9ef1622485cef22e72d6fea32a36': 9000546,
  '0xe595456846155e23b24cc9cbee910ee97027db6d': 8966747,
  '0xae611c6d4d3ca2cee44cd34eb7aac29d5a387fcf': 8936162,
  '0x135827eaf9746573c0b013f18ee12f138b9b0384': 8768702,
  '0x09b8a5f51c9e245402057851ada274174fa00e2a': 8738260,
  '0x53a2d1db049b5271c6b6db020dba0e8a7c4eb90d': 8707191,
  '0x4da8da81647ee0aa7350e9959f3e4771eb753da0': 8686147,
  '0x0446b8f8474c590d2249a4acdd6eedbc2e004bca': 8600355,
  '0x391240A007Bfd8A59bA74978D691219a76c64c5C': 8571013,
  '0x017DEa5C58c2Bcf57FA73945073dF7AD4052a71C': 8542692,
  '0x6Bd94783caCef3fb7eAa9284f1631c464479829f': 8542727,
  '0x7c71723fB1F9Cfb250B702cfc4eBd5D9Ab2E83d9': 8514242,
  '0x9C8813d7D0A61d30610a7A5FdEF9109e196a3D77': 8509358,
  '0xa07a91da6d10173f33c294803684bceede325957': 8485573,
  '0x88c321d444c88acf3e747dc90f20421b97648903': 8481687,
  '0x3c7234c496d76133b48bd6a342e7aea4f8d87fc8': 8479284,
  '0x64473c33c360f315cab38674f1633505d1d8dcb2': 8399111,
  '0x5cc7a19a50be2a6b2540ebcd55bd728e732e59c3': 8371219,
  '0x2666e2494e742301ffc8026e476acc1710a775ed': 8366484,
  '0x6ac2213F09A404c86AFf506Aa51B6a5BF1F6e24E': 8338815,
  '0x35BD47263f7E57368Df76339903C53bAa99076e1': 8313186,
  '0x62dEc3A560D2e8A84D30752bA454f97b26757877': 8285705,
  '0x44d1f81e80e43e935d66d65874354ef91e5e49f6': 8199967,
  '0x4ea43fce546975aae120c9eeceb172500be4a02b': 8171307,
  '0x567fd708e788e51b68666b9310ee9df163d60fae': 8142496,
  '0x36f9452083fc9bc469a31e7966b873f402292433': 8113900,
  '0xc612680457751d0d01b5d901ad08132a3b001900': 8081951,
  '0x336bcd59f2b6eb7221a99f7a50fd03c6bf9a306b': 7993938,
  '0x2b3974dda76b2d408b7d680a27fbb0393e3cf0e1': 7970128,
  '0xfa67f97eeee6de55d179ecabbfe701f27d9a1ed9': 7941368,
  '0x48852322a185dc5fc733ff8c8d7c6dcbd2b3b2a2': 7936185,
  '0xf4d0f71698f58f221911515781b05e808a8635cb': 7912600,
  '0x9dceb1d92f7e0361d0766f3d98482424df857654': 7886546,
  '0xb77f1425ec3a7c78b1a1e892f72332c8b5e8ffcb': 7797976,
  '0xb9ff4da0954b300542e722097671ead8cf337c17': 7795721,
  '0xb19395702460261e51edf7a7b130109c64f13af9': 7791094,
  '0x6e113ecb9ff2d271140f124c2cc5b5e4b5700c9f': 7774682,
  '0x7baf1763ce5d0da8c9d85927f08a8be9c481ce50': 7763795,
  '0x2b8d6c9c62bfc1bed84724165d3000e61d332cab': 7740949,
  '0x8a06ff2748edcba3fb4e44a6bfda4e46769e557b': 7734914,
  '0x3eba95f5493349bbe0cad33eaae05dc6a7e26b90': 7712180,
  '0x593edbd14a5b7eec828336accca9c16cc12f04be': 7707414,
  '0xD714738837944C3c592477249E8edB724A76e068': 7683439,
  '0x8ea9f2482b2f7b12744a831f81f8d08714adc093': 7595034,
  '0x8e8125f871eb5ba9d55361365f5391ab437f9acc': 7592584,
  '0x0e09205e993f78cd5b3a5df355ae98ee7d0b5834': 7569884,
  '0xf9f00d41b1f4b3c531ff750a9b986c1a530f33d9': 7563950,
  '0x4Af531EcD50167a9402Ce921ee6436dd4cFC04FD': 7537787,
  '0x9b4bac2d8f69853aa29cb45478c77fc54532ac22': 7504587,
  '0x20ee70a07ae1b475cb150dec27930d97915726ea': 7504711,
  '0x017556dffb8c6a52fd7f4788adf6fb339309c81b': 7483047,
  '0xdaa711ecf2ac0bff5c82fceeae96d0008791cc49': 7478312,
  '0x74af842ecd0b6588add455a47aa21ed9ba794108': 7398899,
  '0x42d41749d6e9a1c5b47e27f690d4531f181b2159': 7393436,
  '0xbebd44824631b55991fa5f2bf5c7a4ec96ff805b': 7364604,
  '0x55131f330c886e3f0cae389cedb23766ac9aa3ed': 7364573,
  '0x01453a74a94687fa3f99b80762435855a13664f4': 7364532,
  '0x0032ceb978fe5fc8a5d5d6f5adfc005e76397e29': 7336569,
  '0x439b46d467402cebc1a2fa05038b5b696b1f4417': 7315245,
  '0x377ae5f933aa4cfa41fa03e2cae8a2befccf53b2': 7310911,
  '0xce3ebac3f549ebf1a174a6ac3b390c179422b5f6': 7288415,
  '0xd26dec254c699935c286cd90e9841dcabf1af72d': 7202120,
  '0x93e2867d9b74341c2d19101b7fbb81d6063cca4d': 7202111,
  '0x3b644e44033cff70bd6b771904225f3dd69dfb6d': 7202129,
  '0x0a687d7b951348d681f7ed5eea84c0ba7b9566dc': 7202095,
  '0x417df1c0e6a498eb1f2247f99032a01d4fafe922': 7174742,
  '0xdc8943d806f9dd64312d155284abf780455fd345': 7174739,
  '0xa90a894e5bc20ab2be46c7e033a38f8b8eaa771a': 7155121,
  '0x34ac807e34e534fe426da1e11f816422774aae1c': 7155211,
  '0x31fa2f516b77c4273168b284ac6d9def5aa6dafb': 7155474,
  '0x7112f8988f075c7784666ab071927ae4109a8076': 7155441,
  '0x126dfbcef85c5bf335f8be99ca4006037f417892': 7155150,
  '0x4f0ad2332b1f9983e8f63cbee617523bb7de5031': 7155480,
  '0x9483ca44324de06802576866b9d296f7614f45ac': 7155292,
  '0x72ceec6e2a142678e703ab0710de78bc819f4ce0': 7154396,
  '0x1c6ed21d3313822ae73ed0d94811ffbbe543f341': 7155235,
  '0x1ac0d0333640f57327c83053c581340ebc829e30': 7155279,
  '0xc707e5589aeb1dc117b0bb5a3622362f1812d4fc': 7155305,
  '0x22106cdcf9787969e1672d8e6a9c03a889cda9c5': 7155338,
  '0x999b86e8bba3d4f05afb8155963999db70afa97f': 7155544,
  '0xAF3EfE5fCEeBc603Eada6A2b0172be11f7405102': 6919515,
  '0xf73fdeb26a8c7a4abf3809d3db11a06ba5c13d0e': 6917653,
  '0xaac7171afc93f4b75e1268d208040b152ac65e32': 6788373,
  '0x2c6017269b4324d016ca5d8e3267368652c18905': 6755563,
  '0x675434c68f2672c983e36cf10ed13a4014720b79': 6727114,
  '0x05d6c2d1d687eacfb5e6440d5a3511e91f2201a8': 6725610,
  '0xd623a32da4a632ce01766c317d07cb2cad56949b': 6704396,
  '0xdf75f38dbc98f9f26377414e567abcb8d57cca33': 6701895,
  '0xce64a930884b2c68cd93fc1c7c7cdc221d427692': 6704147,
  '0xc1E70edd0141c454b834Deac7ddDeA413424aEf9': 6610197,
  '0x189d8228CdfDc404Bd9e5bD65ff958cb5fd8855c': 6591299,
  '0x0196c582216e2463f052E2B07Ef8667Bec9Fb17a': 6581224,
  '0x8f84106286c9c8A42bc3555C835E6e2090684ab7': 6559387,
  '0xa8d32b31ECB5142f067548Bf0424389eE98FaF26': 6525159,
  '0xC59aa49aE508050c2dF653E77bE13822fFf02E9A': 6521430,
  '0x14AeA62384789EDA98f444cCb970F6730877d3F9': 6504137,
  '0xebb87dF24D65977cbe62538E4B3cFBD5d0308642': 6470268,
  '0x40918EF8efFF4aA061656013a81E0e5A8A702eA7': 6418014,
  '0x44eC1B26035865D9A7C130fD872670CD7Ebac2bC': 6403550,
  '0x1329ad151dE6C441184E32E108401126AE850937': 6376967,
  '0x9bbDc92474a7e7321B78dcDA5EF35f4981438760': 6348645,
  '0x46530d79b238f809e80313e73715b160c66677aF': 6331114,
  '0x47fD853D5baD391899172892F91FAa6d0cd8A2Aa': 6306408,
  '0xe25aB6F05BBF6C1be953BF2d7df15B3e01b8e5a5': 6305969,
  '0xEB8Fd597921E3Dd37B0F103a2625F855e2C9b9B5': 6290206,
  '0xABFd8d1942628124aB971937154f826Bce86DcbC': 6212173,
  '0x526d3c204255f807C95a99b69596f2f9f72345e5': 6157690,
  '0xAa2082BeE04fc518300ec673F9497ffa6F669dB8': 6157299,
  '0x9096625Bc0d36F5EDa6d44e511641667d89C28f4': 6124392,
  '0x78BD4dB48F8983c3C36C8EAFbEF38f6aC7B55285': 6101629,
  '0x35418e14F5aA615C4f020eFBa6e01C5DbF15AdD2': 6013791,
  '0x3c7cC49a35942fbD3C2ad428a6c22490cd709d03': 5976512,
  '0xF795739737ABcFE0273f4Dced076460fdD024Dd9': 5960102,
  '0x06FF8960F7F4aE572A3f57FAe77B2882BE94Bf90': 5946614,
  '0xe4dD0C50fb314A8B2e84D211546F5B57eDd7c2b9': 5919331,
  '0xb627A7e33Db571bE792B0b69c5C2f5a8160d5500': 5919756,
  '0xadBfFA25594AF8Bc421ecaDF54D057236a99781e': 5919806,
  '0x3e31488f08EBcE6F2D8a2AA512aeFa49a3C7dFa7': 5897526,
  '0x453a75908fb5a36d482d5f8fe88eca836f32ead5': 5890495,
  '0x509C99D73FB54b2c20689708b3F824147292D38e': 5809987,
  '0xF1bd5673Ea4a1C415ec84fa3E402F2F7788E7717': 5756176,
  '0xB4C68A1C565298834360BbFF1652284275120D47': 5729929,
  '0x153e62257F1AAe05d5d253a670Ca7585c8D3F94F': 5674205,
  '0xF682D186168b4114ffDbF1291F19429310727151': 5702890,
  '0xaDdAE5f4dB84847ac9d947AED1304A8e7D19f7cA': 5674130,
  '0x4C32048628D0d32d4D6c52662FB4A92747782B56': 5673909,
  '0x47642101e8D8578C42765d7AbcFd0bA31868c523': 5617915,
  '0x07F8217c68ed9b838b0b8B58C19c79bACE746e9A': 5592245,
  '0x580DC9bB9260A922E3A4355b9119dB990F09410d': 5560943,
  '0x6f0037d158eD1AeE395e1c12d21aE8583842F472': 5492608,
  '0x423382f989C6C289c8D441000e1045e231bd7d90': 5518237,
  '0x0A595623b58dFDe6eB468b613C11A7A8E84F09b9': 5497431,
  '0x9E6dA246d369a41DC44673ce658966cAf487f7b2': 5492640,
  '0x2C0f449387b15793B9da27c2d945dBed83ab1B07': 5416686,
  '0x0c3D6892aa3b23811Af3bd1bbeA8b0740E8e4528': 5405432,
  '0x75C91844c5383A68b7d3A427A44C32E3ba66Fe45': 5405196,
  '0xC58954199E268505fa3D3Cb0A00b7207af8C2D1d': 5376195,
  '0xA5137e08C48167E363Be8Ec42A68f4F54330964E': 5344826,
  '0x6F31B87f51654424Ce57E9F8243E27ed13846CDB': 5297712,
  '0xCE54BA909d23B9d4BE0Ff0d84e5aE83F0ADD8D9a': 5287795,
  '0x3e677dC00668d69c2A7724b9AFA7363e8A56994e': 5205303,
  '0x5Ac8406498dC1921735d559CeC271bEd23B294A7': 5177787,
  '0xb69b6e390cba1F68442A886bC89E955048DAe7E3': 5161578,
  '0xae3001ddb18A6A57BEC2C19D71680437CA87bA1D': 5074913,
  '0x02aa767e855b8e80506fb47176202aA58A95315a': 4933042,
  '0x1c736F4FB20C7742Ee83a4099fE92abA61dFca41': 4920677,
  '0x02861B607a5E87daf3FD6ec19DFB715F1b371379': 4878111,
  '0x73e4E8d010289267dEe3d1Fc48974B60363963CE': 4782073,
  '0xE0565fBb109A3f3f8097D8A9D931277bfd795072': 4747958,
  '0xc3693e3cbc3514d5d07EA5b27A721F184F617900': 4717966,
  '0x2B02d43967765b18E31a9621da640588f3550EFD': 4693262,
  '0x212bb602418C399c29D52C55100fD6bBa12bea05': 4615412,
  '0x04aE8ca68A116278026fB721c06dCe709eD7013C': 4583358,
  '0x1714bAAE9DD4738CDEA07756427FA8d4F08D9479': 4557534,
  '0xcCD0b93cC6ce3dC6dFaA9DB68f70e5C8455aC5bd': 4522354,
  '0x9cB24e9460351bC51d4066BC6AEd1F3809b02B78': 4464382,
  '0x2dcf4cDFf4Dd954683Fe0a6123077f8a025b66cF': 4328377,
  '0x6EFa207ACdE6e1caB77c1322CbdE9628929ba88F': 4272350,
  '0xD0b738eC507571176D40f28bd56a0120E375f73a': 4154100,
  '0xf7a31366732F08E8e6B88519dC3E827e04616Fc9': 3986450,
  '0x9F23658D5f4CEd69282395089B0f8E4dB85C6e79': 3942408,
  '0xB6fd2724cc9c90DD31DA35DbDf0300009dceF97d': 3926970,
  '0x108BFE84Ca8BCe0741998cb0F60d313823cEC143': 3775547,
  '0x4A26b082B432B060B1b00A84eE4E823F04a6f69a': 3692047,
  '0x3cc08B7C6A31739CfEd9d8d38b484FDb245C79c8': 3666642,
  '0xd18E1AEb349ef0a6727eCe54597D98D263e05CAB': 3542873,
  '0x68C7d180bD8F7086D91E65A422c59514e4aFD638': 3542859,
  '0xbE65d7e42E05aD2c4ad28769dc9c5b4b6EAff2C7': 3542809,
  '0x1500fa1afbfe4f4277ed0345cdf12b2c9ca7e139': 3498358,
  '0x624ef5C2C6080Af188AF96ee5B3160Bb28bb3E02': 3337878,
  '0x0554a5D083Abf2f056ae3F6029e1714B9A655174': 3309748,
  '0x543467B17cA5De50c8BF7285107A36785Ab57E56': 3285707,
  '0x65aFEAFaec49F23159e897EFBDCe19D94A86A1B6': 3285727,
  '0x1AD34D8d4D79ddE88c9B6b8490F8fC67831f2CAe': 2711886,
  '0x555Ea72d7347E82C614C16f005fA91cAf06DCB5a': 2600409,
  '0x326D754c64329aD7cb35744770D56D0E1f3B3124': 2590161,
  '0x42Afc29b2dEa792974d1e9420696870f1Ca6d18b': 2402640,
  '0xBb2B66a2c7C2fFFB06EA60BeaD69741b3f5BF831': 2387608,
  '0xFb1088Dae0f03C5123587d2babb3F307831E6367': 2337016,
  '0x9c4EBADa591FFeC4124A7785CAbCfb7068fED2fb': 2128860,
  '0x90F995b9d46b32c4a1908A8c6D0122e392B3Be97': 1991882,
  '0xdc8c45b7F3747Ca9CaAEB3fa5e0b5FCE9430646b': 1985544,
  '0xFF02241a2A1d2a7088A344309400E9fE74772815': 1937661,
  '0xDc938BA1967b06d666dA79A7B1E31a8697D1565E': 1937785,
  '0x07a0A5B67136d40F4d7d95Bc8e0583bafD7A81b9': 1937819,
  '0x21A9A53936E812Da06B7623802DEc9A1f94ED23a': 1913459,
  '0xe7f9A439Aa7292719aC817798DDd1c4D35934aAF': 1913419,
  '0xcec2671C81a0Ecf7F8Ee796EFa6DBDc5Cb062693': 1913395,
}

export default poolsDeployedBlockNumber
